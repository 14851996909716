var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "secondCheck" }, [
    _c("div", { staticClass: "secondCheck-header" }, [
      _vm._v("二次身份校验登录")
    ]),
    _c(
      "div",
      { staticClass: "secondCheck-content" },
      [
        _c("div", { staticClass: "secondCheck-text" }, [
          _vm._v("您的账号开启了二次身份校验登录，"),
          _c("br"),
          _vm._v("请输入手机号码 "),
          _c("span", [_vm._v(_vm._s(_vm.phoneStr))]),
          _vm._v(" 短信验证码。")
        ]),
        _c("el-input", {
          staticStyle: {
            width: "400px",
            display: "inline-block",
            "margin-top": "6px"
          },
          attrs: { placeholder: "请输入验证码", clearable: "" },
          model: {
            value: _vm.smsCode,
            callback: function($$v) {
              _vm.smsCode = $$v
            },
            expression: "smsCode"
          }
        }),
        _c(
          "el-button",
          {
            staticClass: "getcode",
            attrs: { type: "primary", loading: _vm.getCodeLoading },
            on: { click: _vm.send }
          },
          [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showTite,
                    expression: "!showTite"
                  }
                ]
              },
              [_vm._v(_vm._s(this.count) + "s")]
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showTite,
                    expression: "showTite"
                  }
                ]
              },
              [_vm._v("获取验证码")]
            )
          ]
        ),
        _vm.errTipFlag
          ? _c("div", { staticClass: "secondCheck-errTip" }, [
              _vm._v("请输入验证码")
            ])
          : _vm._e(),
        _c("div", { staticClass: "secondCheck-tip" }, [
          _vm._v(
            "如您收不到验证码，请联系客服热线 40088-34569，如您不需要二次身份校验登录，则本次登录后，在系统右上角-账号-账号安全-关闭该功能。"
          )
        ]),
        _c(
          "div",
          { staticClass: "secondCheck-footer" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function($event) {
                    _vm.submit()
                  }
                }
              },
              [_vm._v("提交")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }