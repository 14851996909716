<template>
    <div class="secondCheck">
        <div class="secondCheck-header">二次身份校验登录</div>
        <div class="secondCheck-content">
            <div class="secondCheck-text">您的账号开启了二次身份校验登录，<br>请输入手机号码 <span>{{ phoneStr }}</span> 短信验证码。</div>

            <el-input style="width:400px;display:inline-block;margin-top:6px;" v-model="smsCode" placeholder="请输入验证码"
                clearable></el-input>
            <el-button class="getcode" type="primary" :loading="getCodeLoading" @click="send">
                <span v-show="!showTite">{{ this.count }}s</span>
                <span v-show="showTite" >获取验证码</span>
            </el-button>
            <div class="secondCheck-errTip" v-if="errTipFlag">请输入验证码</div>
            <div class="secondCheck-tip">如您收不到验证码，请联系客服热线 40088-34569，如您不需要二次身份校验登录，则本次登录后，在系统右上角-账号-账号安全-关闭该功能。</div>
            <div class="secondCheck-footer">
                <el-button type="primary" @click="submit()">提交</el-button>
            </div>
        </div>

    </div>
</template>
<script>
const TIME_COUNT = 60; //更改倒计时时间
export default {
    data() {
        return {
            getCodeLoading: false,
            smsCode: '',
            show: true, // 初始启用按钮
            showTite: true, // 初始启用按钮
            count: "", // 初始化次数
            timer: null,
            errTipFlag: false,
            phoneStr: '',
            phone: '',
            userInfo: '',
        }
    },
    activated() {
        this.userInfo = this.cache.getLS("userInfo");
        this.phone = this.userInfo.loginCheckMobile;
        this.errTipFlag = false;
        let phoneLength = this.phone.length;
        this.phoneStr = this.phone.slice(0, 3) + '***' + this.phone.slice(phoneLength - 4, phoneLength);
        this.smsCode = '';
    },
    deactivated() {
        this.timer && clearInterval(this.timer)
        this.showTite = true;
    },
    created() { },
    methods: {
        submit() {
            if (!this.smsCode) {
                this.errTipFlag = true;
                return
            }
            let dto = {
                key: this.$route.query.key,
                smsCode: this.smsCode,
                loginCheckMobile: this.phone,
            };
            this.post('user-service/user/loginCheckMobile', dto, {
                isUseResponse: true,
            }).then(res => {
                this.cache.setLS('lastTime', new Date().getTime())
                this.$message({
                    message: '登录成功！',
                    type: "success"
                });
                res.data.data.account = this.$route.query.account;
                this.cache.setLS("userInfo", res.data.data);
                localStorage.setItem("userId", res.data.data.userId);
                this.cache.setLS("token", res.data.data.accessToken);
                localStorage.setItem("phoneNum", res.data.data.phoneNum);
                if (this.cache.getLS("userInfo")["roleType"] == "1") {
                    this.$router.push("/main/dataOverview/list");
                }else if(this.cache.getLS("loginType") == "2") {//商家登录后默认跳转至商家信息页面
                    this.$router.push("/main/merchantManage/merchantInfo/detail");
                } else {
                    this.getFirstPath();
                }
            })
        },
        send() {
            this.timer = null;
            if(!this.showTite) return

            let dto = {
                mobile: this.phone
            };
            if (!this.timer) {
                this.getCodeLoading = true
                this.post("user-service/weChat/sendMobileCode", dto).then(res => {
                    console.log(res);
                    this.getCodeLoading = false
                    this.count = TIME_COUNT;
                    this.show = false;
                    this.showTite = false;
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= TIME_COUNT) {
                            this.count--;
                        } else {
                            this.showTite = true;
                            this.show = true;
                            clearInterval(this.timer); // 清除定时器
                            this.timer = null;
                        }
                    }, 1000);
                    this.$message({
                        message: '发送成功！',
                        type: 'success',
                    })
                }).catch(err => {
                    this.getCodeLoading = false
                })
            }
        },
        getFirstPath() {
            this.menuList = [
                //  {
                //   icon: "fa fa-bell-o",
                //   name: "消息管理",
                //   menuId: 86,
                //   path: "/main/notice/list",
                //   group: [
                //     {
                //       name: "消息列表",
                //       menuId: 87,
                //       path: "/main/notice/list"
                //     }
                //   ]
                // },
                // {
                //     icon: "fa fa-home",
                //     name: "首页",
                //     menuId: 1,
                //     path: "main/log/list",
                //     group: []
                // },
                {
                    icon: "fa fa-bar-chart",
                    name: "数据概览",
                    menuId: 165,
                    path: "/main/dataOverview/list",
                    group: [{
                        name: "首页",
                        menuId: 166,
                        path: "/main/dataOverview/list"
                    },
                    {
                        name: "用户",
                        menuId: 167,
                        path: "/main/dataUser/list"
                    },
                    {
                        name: "房源",
                        menuId: 168,
                        path: "/main/dataRoom/list"
                    },
                    {
                        name: "设备",
                        menuId: 169,
                        path: "/main/dataDevice/list"
                    },
                    {
                        name: "售货",
                        menuId: 170,
                        path: "/main/dataShop/list"
                    },
                    ]
                },
                {
                    icon: "fa fa-building",
                    name: "房管中心",
                    menuId: 2,
                    path: "/main/apartment/list",
                    group: [{
                        name: "房源列表",
                        menuId: 10,
                        path: "/main/apartment/list"
                    },
                    {
                        name: "房型列表",
                        menuId: 11,
                        path: "/main/house/list"
                    },
                    {
                        name: "房间列表",
                        menuId: 12,
                        path: "/main/room/list"
                    }
                    ]
                },
                {
                    icon: "fa fa-flash",
                    name: "设备管理",
                    menuId: 3,
                    path: "/main/auth-device/list",
                    group: [{
                        name: "网关列表",
                        menuId: 117,
                        path: "/main/gateway/list"
                    }, {
                        name: "门禁列表",
                        menuId: 66,
                        path: "/main/AccessControl/list"
                    },
                    {
                        name: "人证机列表",
                        menuId: 13,
                        path: "/main/auth-device/list"
                    },
                    {
                        name: "绑定记录",
                        menuId: 14,
                        path: "/main/lock/list"
                    },
                    {
                        name: "分离式门禁列表",
                        menuId: 68,
                        path: "/main/separateAccessControl/list"
                    },
                    {
                        name: "蓝牙公钥列表",
                        menuId: 69,
                        path: "/main/BluetoothKey/list"
                        // path: "/main/smartMeter/list"
                    },
                    {
                        name: "电子门禁列表",
                        menuId: 76,
                        path: "/main/electronicAccessControl/list"
                        // path: "/main/smartMeter/list"
                    },
                    {
                        name: "智能电表列表",
                        menuId: 70,
                        path: "/main/smartMeter/list"
                    },
                    {
                        name: "智能水表列表",
                        menuId: 71,
                        path: "/main/smartWaterMeter/list"
                    },
                    {
                        name: "认证记录",
                        menuId: 20,
                        path: "/main/authentication/list"
                    },
                    {
                        name: "智能设备",
                        menuId: 62,
                        path: "/main/ied/list"
                    },
                    {
                        name: "通行记录",
                        menuId: 67,
                        path: "/main/PassRecord/list"
                    },
                    {
                        name: "报警器列表",
                        menuId: 109,
                        path: "/main/alarm/list"
                    },
                    {
                        name: "报警记录",
                        menuId: 110,
                        path: "/main/alarmRecord/list"
                    },
                        // {
                        //   name: "门禁记录",
                        //   menuId: 51,
                        //   path: "/main/guard/list"
                        // }
                    ]
                },
                {
                    icon: "fa fa-file-text-o",
                    name: "订单管理",
                    menuId: 4,
                    path: "/main/order/list",
                    group: [{
                        name: "订单列表",
                        menuId: 15,
                        path: "/main/order/list"
                    },
                    {
                        name: "退款列表",
                        menuId: 64,
                        path: "/main/Refundlist/list"
                    }
                    ]
                },
                {
                    icon: "fa fa-yen",
                    name: "财务管理",
                    menuId: 5,
                    path: "/main/finance/overview",
                    group: [{
                        name: "财务概览",
                        menuId: 16,
                        path: "/main/finance/overview"
                    },
                    {
                        name: "销售统计",
                        menuId: 17,
                        path: "/main/sale/list"
                    },
                    {
                        name: "对账管理",
                        menuId: 18,
                        path: "/main/statement/list"
                    },
                    {
                        name: "提款记录",
                        menuId: 58,
                        path: "/main/record/list"
                    },
                    {
                        name: "转账记录",
                        menuId: 59,
                        path: "/main/money/list"
                    }
                    ]
                },
                // {
                //     icon: 'fa fa-user-plus',
                //     name: '房东管理',
                //     menuId: 24,
                //     path: '/main/landlord/list',
                //     group: []
                // },
                {
                    icon: "fa fa-group",
                    name: "用户管理",
                    menuId: 6,
                    path: "/main/tenant/list",
                    group: [
                    {
                        name:"用户列表",
                        menuId: 160,
                        path: "/main/userList/list"
                    },
                    {
                        name: "房客列表",
                        menuId: 19,
                        path: "/main/tenant/list"
                    },
                    {
                        name: '商户列表',
                        menuId: 89,
                        path: '/main/landlord/list'
                    },
                    
                    ]
                },
                {
                    icon: "fa fa-book",
                    name: "租约管理",
                    menuId: 104,
                    path: "/main/leaseManagement/list",
                    group: [{
                        name: "租约列表",
                        menuId: 105,
                        path: "/main/leaseManagement/list"
                    },{
                        name: "退房列表",
                        menuId: 65,
                        path: "/main/CheckOut/list"
                    },{
                        name: "约看列表",
                        menuId: 51,
                        path: "/main/date/list"
                    }]
                },
                // {
                //     icon: "fa fa-tripadvisor",
                //     name: "约看管理",
                //     menuId: 50,
                //     path: "/main/date/list",
                //     group: []
                // },
                {
                    icon: "fa fa-unlock-alt",
                    name: "访问控制",
                    menuId: 25,
                    path: "/main/account/list",
                    group: [{
                        name: "房管员列表",
                        menuId: 26,
                        path: "/main/account/list"
                    },
                    {
                        name: "权限管理",
                        menuId: 27,
                        path: "/main/jurisdiction/list"
                    }
                    ]
                },
                {
                    icon: "fa fa-file-text-o",
                    name: "合同管理",
                    menuId: 52,
                    path: "/main/contract/list",
                    group: [{
                        name: "合同列表",
                        menuId: 53,
                        path: "/main/contract/list"
                    },
                    {
                        name: "合同模板",
                        menuId: 75,
                        path: "/main/ContractTemplate/list"
                    }
                    ]
                },
                {
                    icon: "fa fa-list",
                    name: "售货管理",
                    menuId: 80,
                    path: "/main/cargoController/list",
                    group: [{
                        name: "会员列表",
                        menuId: 113,
                        path: "/main/membersManagement/list"
                    },
                    {
                        name: "货管员列表",
                        menuId: 81,
                        path: "/main/cargoController/list"
                    },
                    {
                        name: "门店管理",
                        menuId: 106,
                        path: "/main/groupManagement/list"
                    },
                    {
                        name: "货柜管理",
                        menuId: 82,
                        path: "/main/containerManagement/list"
                    },
                    {
                        name: "品类管理",
                        menuId: 83,
                        path: "/main/categoryManagement/list"
                    },
                    {
                        name: "商品管理",
                        menuId: 84,
                        path: "/main/commodityManagement/list"
                    },
                    {
                        name: "仓库管理",
                        menuId: 116,
                        path: "/main/storehouse/list"
                    },
                    {
                        name: "报损记录",
                        menuId: 112,
                        path: "/main/lossReportRecord/list"
                    },
                    {
                        name: "售货订单",
                        menuId: 85,
                        path: "/main/salesOrder/list"
                    },
                    {
                        name: "实时售卖",
                        menuId: 115,
                        path: "/main/goodsOnSale/list"
                    },
                    {
                        name: "退款订单",
                        menuId: 111,
                        path: "/main/refundOrder/list"
                    },
                    {
                        name: "开票记录",
                        menuId: 107,
                        path: "/main/billingRecord/list"
                    }, {
                        name: "营销配置",
                        menuId: 114,
                        path: "/main/marketingConfig/list"
                    },
                    {
                        name: "银行信息管理",
                        menuId: 151,
                        path: "/main/bankManagement/list"
                    },
                    {
                        name: "意见反馈",
                        menuId: 88,
                        path: "/main/containerFeedback/list"
                    }
                    ]
                },
                {
                    icon: "fa fa-cog",
                    name: "系统管理",
                    menuId: 7,
                    path: "/main/user/list",
                    group: [{
                        name: "系统用户",
                        menuId: 21,
                        path: "/main/user/list"
                    },
                    {
                        name: "房源配置",
                        menuId: 177,
                        path: "/main/apartment/config"
                    },
                    {
                        name: "参数设置",
                        menuId: 101,
                        path: "/main/parameter/list"
                    },
                    {
                        name: "广告管理",
                        menuId: 102,
                        path: "/main/adManagement/list"
                    },
                    {
                        name: "广告位管理",
                        menuId: 103,
                        path: "/main/adSpaceManagement/list"
                    },
                    {
                        name: "角色管理",
                        menuId: 22,
                        path: "/main/role/list"
                    },
                    {
                        name: "菜单管理",
                        menuId: 23,
                        path: "/main/menu/list"
                    },
                    // {
                    //   name: "房东管理",
                    //   menuId: 24,
                    //   path: "/main/landlord/list"
                    // },
                    {
                        name: "房东信息",
                        menuId: 61,
                        path: "/main/information/detail/show/0"
                    },
                    {
                        name: "学校信息",
                        menuId: 176,
                        path: "/main/schoolInfo/list"
},
{
                        name: "导出中心",
                        menuId: 171,
                        path: "/main/exportCenter/list"
                    },
                    {
                        menuId: 8,
                        name: "登录日志",
                        path: "/main/log/list"
                    },
                    {
                        menuId: 108,
                        name: "操作日志",
                        path: "/main/operationLog/list"
                    },
                    {
                        menuId: 63,
                        name: "意见反馈",
                        path: "/main/feedback/list"
                    }
                    ]
                }
            ];
            // 房东的数据概览
            if(this.cache.getLS("userInfo")["roleType"] == "2") {
            this.menuList.forEach(item => {
                if(item.menuId == 165) {
                item.path = '/main/landlorDataOverview/list'
                item.group = [{
                    name: "首页",
                    menuId: 166,
                    path: "/main/landlorDataOverview/list"
                },]
                }
            })
            }
            let menuConfig = this.cache.getLS("userInfo").userMenuInfos;
            let allIdList = [];
            if (menuConfig && menuConfig.length) {
                menuConfig.forEach((item) => {
                    allIdList.push(item.menuId);
                    if (item.childMenus.length) {
                        item.childMenus.forEach(data => {
                            allIdList.push(data.menuId);
                        });
                    }
                });
            }
            console.log(allIdList);
            let obj = this.menuList;
            let num = 0;
            this.menuList.forEach((item) => {
                // if (allIdList.indexOf(item.menuId) != -1) {
                //   if(num==0){
                //      console.log(item);
                //     this.$router.push(item.path)
                //   }
                //   num++;
                // }
                item.group.forEach(data => {
                    if (allIdList.indexOf(data.menuId) != -1) {
                        if (num == 0) {
                            console.log(item);
                            this.$router.push(data.path)
                        }
                        num++;
                    }
                });
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.secondCheck-header {
    padding-left: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #c0c4cc;
    height: 46px;
    line-height: 46px;
    color: #333333;
}

.getcode {
    margin-left: 10px;
}

.secondCheck-content {
    padding-left: 60px;
}

.secondCheck-text {
    color: #909399;
    font-size: 15px;
    line-height: 35px;
}

.secondCheck-tip {
    color: #909399;
    font-size: 13px;
    line-height: 30px;
}

.secondCheck-errTip {
    color: red;
    font-size: 13px;
    line-height: 30px;
}

.secondCheck-footer {
    margin-left: 80px;
    margin-top: 50px;
}
</style>